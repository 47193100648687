

// import logo from './logo.svg';
// import './App.css';

import React, {useState} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory,
  Redirect
} from "react-router-dom";


import q from './q.json'

// import MeetingPage from './MeetingsPage';
// import MeetingList from './MeetingList'; 

// import StCards, {ScrImg} from './st/st_cards'; 
// import StyleTransferPage from './st/StyleTransferPage'; 

// import api from './api/api.js'


// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
// import './antd.dark.css'
// import { notification, Button } from 'antd';
import { WarningOutlined, PlusSquareOutlined, FireOutlined, LoadingOutlined, DownloadOutlined, CameraOutlined, CameraFilled } from '@ant-design/icons';
import  {
     
      TextField,
      ActionButton,
      Spinner,
    //   useToast,
    //   ToastProvider

    Container, Row, Col, 
    // Cell, CellIcon,
    Underline,
    Header,
    Card,
    CardBody,
    CardContent,
    CardMedia,
    // CardHeadline1,
    CardParagraph1,
    TextBoxBigTitle,
    TextBoxBiggerTitle,
    TextBoxSubTitle,
    CarouselGridWrapper,
    Carousel,
    // Row,
    // CarouselCol,
    // ProductCard,
    TextBox,
    Button,
    Tabs, TabItem,

    // CarouselSection,
    CarouselCol,
    // MusicCard,

    

    Badge,
    useRemoteHandlers,
    // useToast,
    // TextField,
    // ActionButton,
    // Checkbox,
    // Toast,
    // ToastProvider,
 } from '@sberdevices/plasma-ui';

import {
    IconEye,
    IconArrowRight
} from '@sberdevices/plasma-icons'

import axios from 'axios';

// import QRmodal from './qrmodal'


import { Row as Row2, Col as Col2 } from 'antd';

import { IRow, ICol } from './../st/irow'

import utils from './../st/utils'

import { message } from 'antd';



function Item({q}) {
    return (
        <div>

        <Card
            style={{ background: '#ffffffdd'}}
            className="q-item"
            tabIndex={1}
            outlined={true}
            scaleOnFocus={false}
            // onClick={()=>history.push("/meetings/" + item.id)}
        >
            <CardBody>
                <CardContent cover={false}>

                    <Row>
                        <Col size={3.5}>
                            <CardParagraph1 lines={4} style={{marginBottom:'10px'}}>
                                {q['автор']}
                            </CardParagraph1>
                            <TextBox>
                                <TextBoxBigTitle>{q['текст']}</TextBoxBigTitle>
                                {/* <TextBoxBiggerTitle>{item.meeting_core}</TextBoxBiggerTitle> */}
                                {/* <TextBoxSubTitle>{item.meeting_points.join('; ')}</TextBoxSubTitle> */}
                            </TextBox>
                            <CardParagraph1 style={{ marginTop: '0.75rem' }} lines={4}>
                                <Badge text={'2 июня 2021, 15:43'} view={'clear'} style={{paddingLeft: 0, marginLeft:'-0.25rem'}}></Badge>
                            </CardParagraph1>
                        
                        </Col>
                        <Col size={2} style={{paddingLeft:'50px', opacity: 1, lineHeight: 1.3}}>
                            {/* {q['результаты моделей']} */}

                            <br/>
                            {/* <big style={{fontSize:'40px'}}>{q['smile']}</big> */}
                            <br/>
                            {/* {q['Informative']['Polite']} */}
                            <small>
                            Результаты моделей:
                            <br/>
                            💧 &nbsp; Воды — 0 %
                            <br/>
                            💼  &nbsp; Политики — 0 %
                            <br/>
                            😡  &nbsp; Негатив — 0 %
                            <br/>
                            🙈  &nbsp; Когнитивных искажений — 0

                        
                                
                                {/* { JSON.stringify( q['результаты моделей'] ) } */}
                            </small>
                        </Col>
                    </Row>

                </CardContent>
            </CardBody>
        </Card>

        <br />


        </div>
    )
}



export default function MainPage() {

    let [text, setText] = useState('')
    let [name, setName] = useState('')


    let [is_loading, setLoadading] = useState(false)


    const success = () => {
        message.success({
            content: 'Спасибо! Ваш комментарий отправлен',
            className: 'custom-class',
            style: {
                marginTop: '40vh',
                borderRadius: '100px',
                padding: '40px'
            },
        });
    };

    const send = () => {
        setLoadading(true)
        setTimeout(()=> {
            setLoadading(false);
            success()
            setText('')
            setName('')
        },300)
    }

    return (
        <div>
            <Container style={{maxWidth:'calc(100% - 300px)', margin:'0 0', color:'#151a22'}}>

                <div style={{textAlign:'left', marginTop:'2rem'}}>
                    
                    <img className="sber_180" src="/sber_180.png" style={{ width:'200px', position: 'fixed', top:'30px', right: '30px'}} />

                    <h1 style={{fontSize: '3rem', lineHeight:1, margin:'1rem 0 1rem'}}>Вопросы из зала</h1>
                    <br />
                    {/* <p>Выборка основных тем</p> */}
                </div>


                { q.questions.map(x => <Item q={x} />)}


                <div class="control-panel">
                    <p>Всего вопросов</p>
                    <h3>34</h3>

                    <br/>
                    <br/>
                    {/* <p>Отобрано</p> */}
                    {/* <h3>7</h3> */}


                </div>

                <br/>
                <br/>
                <br/>
                <br/>

                {/* Напишите слово или фразу */}

                {/* Ваше имя, как вас представить (опционально) */}
              


                {/* <ActionButton view="primary" onClick={() => go_hand(val, setHandimg, setHandimg2, setLoading)}> */}
                        {/* <IconArrowRight color="inherit" size="s" /> */}
                        {/* <IconEye color="inherit" size="s" /> */}
                        {/* <Spinner color="white" size={20}/> */}
                {/* </ActionButton> */}

                {/* <Button view="primary" onClick={() => { showToast(toastText, position, timeout, fade); }} >Отправить комментарий</Button> */}
                {/* <Button view="primary" onClick={() => { send() }} >
                    { is_loading ? <Spinner color="white" size={20} /> : 'Отправить комментарий' }
                </Button> */}




                {/* <ToastProvider /> */}
                    {/* <div className="App">Hello, world!</div> */}
                {/* </ToastProvider> */}
                

                <div className="footer" style={{textAlign:'right', position: 'fixed', right: '20px', bottom: '20px'}}>
                    <small>powered by</small>
                    <img src="sberai_logo_black.svg" style={{verticalAlign: 'middle', width: '90px', margin: '10px'}}/> 
                </div>

            </Container>

            <style>
              {`

                    html, body { min-height: 100vh; } 

                    body { 
                        background: #d5dae1 url('/sberlight_bg.jpg') center top no-repeat; 
                        background-attachment: fixed;
                    }

                    .TextField input {
                        color:#2e3340;
                        background: #ffffff9b;
                    }
                    .TextFieldArea textarea { 
                      height: 30vh; 
                      resize: none;
                      white-space: normal;
                      background: #ffffff9b;
                      overflow: auto;
                      color:#2e3340;
                    }
                    .TextField input:focus,
                    .TextFieldArea textarea:focus { 
                      background: #fff;
                    }
                    .TextField span,
                    .TextFieldArea span {
                        color: #2e334077;
                    }

                    .q-item * {
                        color: #000 !important;
                    }

                    .control-panel {
                        position: fixed;
                        right: 40px;
                        top: 30vh;
                        text-align:right
                    }

                    .control-panel * {
                        margin: 5px 0;
                    }

                    .control-panel h3 {
                        font-size: 30px;
                    }
                    

              `}
          </style>

        </div>
    )
}