

export function img_blob_to_url(blob) {
	let urlCreator = window.URL || window.webkitURL;
	return urlCreator.createObjectURL( new Blob([blob], {type: "image/jpeg"}) )
}

export function download(data, filename = 'style_transfer.jpg', type = "image/jpeg") {
	let urlCreator = window.URL || window.webkitURL;
  
	var file = new Blob([data], {type: type});
	if (window.navigator.msSaveOrOpenBlob) // IE10+
		window.navigator.msSaveOrOpenBlob(file, filename);
	else { // Others
		var a = document.createElement("a"),
			url = urlCreator.createObjectURL(file);
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		setTimeout(function() {
			document.body.removeChild(a);
			urlCreator.revokeObjectURL(url);  
		}, 0); 
	}
  }

  export default {
    img_blob_to_url,
    download
  }