

// import logo from './logo.svg';
// import './App.css';

import React, {useState} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory,
  Redirect
} from "react-router-dom";


// import MeetingPage from './MeetingsPage';
// import MeetingList from './MeetingList'; 

// import StCards, {ScrImg} from './st/st_cards'; 
// import StyleTransferPage from './st/StyleTransferPage'; 

// import api from './api/api.js'


// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
// import './antd.dark.css'
// import { notification, Button } from 'antd';
import { WarningOutlined, PlusSquareOutlined, FireOutlined, LoadingOutlined, DownloadOutlined, CameraOutlined, CameraFilled } from '@ant-design/icons';
import  {
      Button, Container,
      TextField,
      ActionButton,
      Spinner,
    //   useToast,
    //   ToastProvider

} from '@sberdevices/plasma-ui';

import {
    IconEye,
    IconArrowRight
} from '@sberdevices/plasma-icons'

import axios from 'axios';

// import QRmodal from './qrmodal'


import { Row as Row2, Col as Col2 } from 'antd';

import { IRow, ICol } from '../st/irow'

import utils from '../st/utils'

import { message } from 'antd';


export default function MainPage() {

    let [text, setText] = useState('')
    let [name, setName] = useState('')


    let [is_loading, setLoadading] = useState(false)


    const success = () => {
        message.success({
            content: 'Спасибо! Ваш комментарий отправлен',
            className: 'custom-class',
            style: {
                marginTop: '40vh',
                borderRadius: '100px',
                padding: '40px'
            },
        });
    };

    const send = () => {
        
    }

    return (
        <div>
            <Container style={{maxWidth:'500px', margin:'0 auto'}}>

                <div style={{textAlign:'center', marginTop:'2rem'}}>
                    <br />
                    <img className="sber_180" src="/sber_180.png" style={{ width:'200px'}} />

                    <h1 style={{fontSize: '3rem', lineHeight:1, margin:'1rem 0 1rem'}}>Поделитесь <br/>вашей эмоцией</h1>
                    <p>Напишите одно слово, <br/>которое раскроет ваше настроение</p>
                </div>




                <TextField 
                    // as="textarea"
                    className="TextField"
                    label="Ваша главная эмоция (1 слово)"
                    // rows={10}
                    value={name} 
                    style={{textAlign:'center' }}
                    // defaultValue={val} 
                    onChange={e => setName(e.target.value)} 
                    // onKeyPress={e => { if (e.code == 'Enter') go_hand(val, setHandimg, setHandimg2, setLoading) }}
                    // style={{ margin: '10px auto', width:'100%'}}
                />

                <br />

                {/* <ActionButton view="primary" onClick={() => go_hand(val, setHandimg, setHandimg2, setLoading)}> */}
                        {/* <IconArrowRight color="inherit" size="s" /> */}
                        {/* <IconEye color="inherit" size="s" /> */}
                        {/* <Spinner color="white" size={20}/> */}
                {/* </ActionButton> */}

                {/* <Button view="primary" onClick={() => { showToast(toastText, position, timeout, fade); }} >Отправить комментарий</Button> */}
                <Button view="primary" onClick={() => { success(); setLoadading(!is_loading) }} >
                    { is_loading ? <Spinner color="white" size={20} /> : 'Отправить' }
                </Button>




                {/* <ToastProvider /> */}
                    {/* <div className="App">Hello, world!</div> */}
                {/* </ToastProvider> */}
                

                <div className="footer" style={{textAlign:'center', margin: '2rem 0'}}>
                    <small>powered by</small>
                    <img src="sberai_logo.svg" style={{verticalAlign: 'middle', width: '90px', margin: '10px'}}/> 
                </div>

            </Container>

            <style>
              {`


                    
              `}
          </style>

        </div>
    )
}